<template>
  <div>
    <el-card v-if="id">
      <div slot="header" class="clearfix">
        <span class="title">
          订单详情
          <span class="backBtn" @click="goBack">返回</span>
        </span>
        <el-button
          v-if="detail.payStatus == 'SUCCESS'"
          class="refundBtn"
          type="text"
          @click="refund"
          >申请退款</el-button
        >
      </div>
      <div>
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item label="订单编号：">
            <div>{{ detail.orderId }}</div>
          </el-form-item>
          <el-form-item label="订单状态：">
            <div>{{ detail.payStatusName }}</div>
          </el-form-item>
          <el-form-item label="订单明细：">
            <el-button v-show="detail.orderType == 8" plain @click="statisticsExport"
              >导出订单明细</el-button
            >
            <el-table
              v-show="detail.orderType == 8"
              :data="detail.page.list"
              border
              style="width:700px;margin-top:18px"
            >
              <el-table-column prop="productName" label="类目"> </el-table-column>
              <el-table-column prop="studentName" label="学员姓名"> </el-table-column>
              <el-table-column prop="productAmount" label="单价"> </el-table-column>
              <el-table-column prop="buyMethodShow" label="购买方式"> </el-table-column>
            </el-table>
            <el-table
              v-show="detail.orderType != 8"
              :data="detail.organizationOrderDetailReqDtos"
              border
              :summary-method="getSummaries"
              show-summary
              style="width:700px"
            >
              <el-table-column prop="productName" label="类目"> </el-table-column>
              <el-table-column v-if="detail.orderType == 4" prop="expireYears" label="年数">
              </el-table-column>
              <el-table-column prop="productAmount" label="单价"> </el-table-column>
              <el-table-column prop="productCount" label="数量"> </el-table-column>
              <el-table-column prop="productSumAmount" label="总价"> </el-table-column>
            </el-table>
          </el-form-item>
          <el-pagination
            v-show="detail.orderType == 8"
            class="pagination"
            :current-page="queryParam.current"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="queryParam.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="queryParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
          <el-form-item label="支付渠道：">
            <div>{{ detail.payTypeName }}</div>
          </el-form-item>
          <el-form-item label="订单创建时间：">
            <div>{{ detail.createTimeShow }}</div>
          </el-form-item>
          <el-form-item label="订单支付时间：">
            <div>{{ detail.payTimeShow }}</div>
          </el-form-item>
          <div class="line"></div>
          <div class="orderNum">
            <el-form-item label="订单金额：">
              <div class="orderSum">￥{{ detail.orderAmount }}元</div>
            </el-form-item>
            <el-form-item label="优惠金额：">
              <div class="orderSum">￥0.00</div>
            </el-form-item>
            <el-form-item label="订单实付金额：">
              <div class="orderSum">￥{{ detail.payAmount }}元</div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="payBtn">
        <el-button
          v-if="detail.payStatus !== 'SUCCESS' && detail.payStatus !== 'REFUND'"
          type="primary"
          :loading="submitLoading"
          @click="goContinuePay"
          >去支付</el-button
        >
      </div>
    </el-card>

    <el-card v-else>
      <div slot="header" class="clearfix">
        <span class="title">订单详情</span>
      </div>
      <el-form ref="formLive" :model="formLive" label-width="150px">
        <el-form-item label="订单类型：">
          <div>{{ liveDetail.livePackageName }}</div>
          <div>{{ liveDetail.description }}</div>
          <div>{{ liveDetail.chargePrice }}元/月</div>
        </el-form-item>
        <el-form-item label="生效时间：">
          <div>{{ starTime }} -{{ endTime }}</div>
        </el-form-item>
        <el-form-item label="订单金额：">
          <div>{{ (liveDetail.chargePrice * month).toFixed(2) }}元</div>
        </el-form-item>
        <el-form-item label="订单明细：">
          <el-table
            :data="list"
            border
            :summary-method="getSummaries"
            show-summary
            style="width:800px"
          >
            <el-table-column prop="livePackageName" label="类目"> </el-table-column>
            <el-table-column prop="chargePrice" label="单价"> </el-table-column>
            <el-table-column prop="num" label="数量"> </el-table-column>
            <el-table-column prop="num" label="月"> </el-table-column>
            <el-table-column prop="productSumAmount" label="总价"> </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="支付方式：">
          <div class="payType">
            <div class="balancePay">
              <img src="../../../assets/college/Accounts/wrap.png" alt="" />
              <span class="balance">余额支付</span>
            </div>
            <div class="nowMoney">
              当前余额<span class="moneyNum">{{ accountsDetail.balanceAmount }}</span
              >元
            </div>
            <div class="recharge" @click="recharge">充值</div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            :loading="submitLoading"
            class="btn"
            @click="surePay"
            >确定支付</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <!-- <Recharge v-if="detail.orderType == 4 || liveDetail" ref="Recharge"></Recharge> -->
    <BalancePay ref="BalancePay" @success="paySuccess" />
  </div>
</template>

<script>
// import Recharge from '@/views/college/Accounts/commonents/Recharge'
import BalancePay from '@/components/BalancePay'
import { getOrderList, orderExport } from '@/api/finance'
import { getAccountsMoney } from '@/api/college'
import { buyMeal } from '@/api/live'
import to from 'await-to'
import moment from 'moment'
export default {
  components: {
    BalancePay,
    // Recharge
  },
  data() {
    return {
      form: {},
      formLive: {},
      id: this.$route.query.id,
      detail: {
        page: {
          list: [],
        },
      },
      current: this.$route.query.current,
      submitLoading: false,
      liveDetail: {},
      month: this.$route.query.month,
      list: [],
      starTime: '',
      endTime: '',
      accountsDetail: {},
      payStatus: this.$route.query.payStatus,
      queryParam: {
        current: 1,
        size: 10,
        total: 500,
      },
    }
  },
  created() {
    if (this.id) {
      this.getOrderListData()
    } else {
      this.getAccountsMoneyData()
      this.starTime = moment().format('YYYY-MM-DD')
      this.endTime = moment()
        .add(this.month, 'month')
        .format('YYYY-MM-DD')
      this.liveDetail = JSON.parse(this.$route.query.liveDetail)
      this.list.push(this.liveDetail)
      this.list.map(v => {
        this.$set(v, 'num', this.month)
        this.$set(v, 'productSumAmount', (this.month * this.liveDetail.chargePrice).toFixed(2))
      })
    }
  },
  methods: {
    statisticsExport() {
      orderExport(
        {
          classId: this.detail.classId,
          orderId: this.detail.orderId,
        },
        { responseType: 'blob' },
      ).then(res => {
        let blob = new Blob([res], {
          type: 'application/vnd.ms-excel',
        })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = this.detail.orderId + ' ' + this.detail.className
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.accountsDetail = res.data
    },
    async getOrderListData() {
      const [res, err] = await to(
        getOrderList({
          id: this.id,
          current: this.current,
          payStatus: this.payStatus,
          queryParam: this.queryParam,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      res.data.list.map(v => {
        if (v.id == this.id) this.detail = v
      })
      if (res.data.list.length !== 0) {
        this.queryParam.total = res.data.list[0].page.total
      }
    },
    async goContinuePay() {
      let params = {
        orderId: this.detail.orderId,
        orderType: this.detail.orderType,
        payType: 'BALANCE',
      }
      // console.log(obj, continuePay)

      this.$refs.BalancePay.show(this.detail.orderAmount, this.detail.orderId, params)
      // if (this.detail.orderType == 4) {
      //   this.$refs.Recharge.show(this.detail.orderAmount, this.detail.orderId)
      // } else {
      //   this.submitLoading = true
      //   const [, err] = await to(continuePay(obj))
      //   if (err) return this.$message({ type: 'error', message: err.msg })
      //   this.submitLoading = false
      //   this.$message({ type: 'success', message: '支付成功' })
      //   this.$router.push('/finance/orderList')
      // }
    },
    paySuccess() {
      this.$message({ type: 'success', message: '支付成功' })
      this.$router.push('/finance/orderList')
    },
    async surePay() {
      let params = {
        addOrganiztaionOrderDetailReqDtos: [
          {
            amount: this.liveDetail.chargePrice * this.month,
            productAmount: this.liveDetail.chargePrice,
            productCount: this.month,
            productName: this.liveDetail.livePackageName,
            productId: this.liveDetail.livePackageId,
            productSumAmount: this.liveDetail.chargePrice * this.month,
            originalPrice: this.liveDetail.chargePrice * this.month,
          },
        ],
        orderAmount: this.liveDetail.chargePrice * this.month,
        orderSourseTypePay: 1,
        orderType: 9,
        orderSourseType: 1,
      }
      this.submitLoading = true
      const [, err] = await to(buyMeal(params))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.submitLoading = false
      this.$message({ type: 'success', message: '购买成功' })
      this.$router.push('/college/accountsManage')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      //   console.log(columns, data)
      var price = 0
      data.map(v => {
        price += Number(v.productSumAmount)
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = price.toFixed(2) + '元'
          return
        }
      })

      return sums
    },
    recharge() {
      // this.$refs.Recharge.show()
      this.$router.push({
        name: 'AccountsManage',
        params: {
          showRecharge: 1,
        },
      })
    },
    goBack() {
      this.$router.push('orderList')
    },
    refund() {
      this.$message({ type: 'warning', message: '暂未开通此功能' })
    },
    handleSizeChange(val) {
      this.queryParam.current = 1
      this.queryParam.size = val
      this.getOrderListData()
    },
    handleCurrentChange(val) {
      this.queryParam.current = val
      this.getOrderListData()
    },
  },
}
</script>

<style scoped lang="scss">
.backBtn {
  display: inline-block;
  padding: 5px 12px;
  color: #fff;
  font-size: 14px;
  background-color: #ff7b33;
  margin-left: 20px;
  cursor: pointer;
}
.refundBtn {
  float: right;
  padding: 3px 0;
}
.payBtn {
  text-align: center;
}
.payType {
  background-color: #fff5f0;
  display: flex;
  align-items: center;
  padding-left: 20px;
  img {
    width: 20px;
    height: 17px;
    margin: auto;
  }
  .balancePay {
    display: flex;
    align-items: center;
    .balance {
      margin-left: 6px;
      color: #333;
    }
  }
  .nowMoney {
    color: #909399;
    margin: 0 39px 0 12px;
    .moneyNum {
      color: #ff7b33;
    }
  }
  .recharge {
    line-height: 24px;
    text-align: center;
    color: #ff7b33;
    border: 1px solid #ff7b33;
    cursor: pointer;
    padding: 0 12px;
  }
}
.btn {
  margin-top: 38px;
}
.pagination {
  margin-left: 148px;
  width: 700px;
  margin-bottom: 80px;
}
.line {
  height: 1px;
  background-color: #e2e2e2;
  margin-bottom: 20px;
}
.orderNum {
  margin-left: 600px;
}
.orderSum {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #ff7b33;
}
</style>
