<!--
 * @Description: 余额支付弹窗
 * @Author: xiawenlong
 * @Date: 2021-11-04 08:46:25
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-04 09:27:09
-->
<template>
  <el-dialog
    title="支付方式"
    :visible.sync="dialogVisible"
    width="480px"
    :close-on-click-modal="false"
    class="balance-pay"
  >
    <div class="content">
      <div class="form-item">
        <div class="key">订单金额：</div>
        <div class="value">
          <span class="money">￥{{ continuePayMoney }}</span>
        </div>
      </div>
      <div class="form-item">
        <div class="key">支付方式：</div>
        <div class="value">
          <div>
            <el-radio-group v-model="radio">
              <div class="radio_wrap">
                <el-radio label="BALANCE">
                  <span>余额支付</span>
                </el-radio>
              </div>
            </el-radio-group>
            <div class="recharge" @click="toRecharge">充值</div>
          </div>
          <div class="balance">
            余额：<span>{{ actualAmount }}元</span>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="submitLoading" type="primary" @click="pay">确认支付</el-button>
    </div>
  </el-dialog>
</template>
<script>
import to from 'await-to'
import { getAccountsMoney } from '@/api/college'
import { continuePay } from '@/api/finance'
export default {
  name: 'BalancePay',
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      continuePayMoney: '',
      queryOrderId: '',
      radio: 'BALANCE',
      actualAmount: 0,
      params: {},
    }
  },
  methods: {
    show(continuePayMoney = '', queryOrderId = '', params = {}) {
      this.dialogVisible = true
      this.continuePayMoney = continuePayMoney
      this.orderId = queryOrderId
      this.params = params
      this.getAccountsMoneyData()
    },
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.actualAmount = res.data.actualAmount
    },
    toRecharge() {
      this.$router.push({
        name: 'AccountsManage',
        params: {
          showRecharge: 1,
        },
      })
    },
    async pay() {
      this.submitLoading = true
      const [, err] = await to(continuePay(this.params))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.submitLoading = false
      this.$emit('success')
    },
  },
}
</script>
<style lang="scss" scoped>
.balance-pay {
  ::v-deep .el-dialog__header {
    height: 55px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 55px;
    padding: 0 14px 0 17px;
    border-bottom: 1px solid #e2e2e2;
  }
  ::v-deep .el-dialog__body {
    padding: 30px 0 0 39px;
    .content {
      padding-bottom: 43px;
      .form-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        &:first-child {
          align-items: center;
        }
        .value {
          .money {
            font-size: 20px;
            font-weight: 700;
            color: #333333;
          }
          > div {
            display: flex;
            align-items: center;
          }
          .el-radio {
            display: flex;
            align-items: center;
            padding-top: 1px;
            .el-radio__input {
              margin-top: 3px;
            }
            .el-radio__label {
              padding-top: 1px;
            }
          }
          .recharge {
            cursor: pointer;
            line-height: 15px;
            margin-left: 44px;
            font-size: 14px;
            color: #ff7b33;
            padding-top: 1px;
          }
          .balance {
            margin-top: 10px;
            font-size: 14px;
            color: #909399;
            span {
              color: #ff583e;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #e2e2e2;
    .el-button {
      width: 100px;
      height: 32px;
      background: #ff7b33;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
